import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import Table01 from 'components/Software/Other_Platforms/Lupus_XT2_Plus/Table01';
import Table02 from 'components/Software/Other_Platforms/Lupus_XT2_Plus/Table02';
import Table03 from 'components/Software/Other_Platforms/Lupus_XT2_Plus/Table03';
import NavButtons from 'components/Software/Other_Platforms/NavButtons';
import ForumBox from 'components/Software/Other_Platforms/Lupus_XT2_Plus/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Lupus XT2 Plus",
  "path": "/Software/Other_Platforms/Lupus_XT2_Plus/",
  "dateChanged": "2020-04-21",
  "author": "Mike Polinowski",
  "excerpt": "Integrate video surveillance systems such as IP cameras from LUPUS and lots of other manufacturers simply into the LUPUSEC user interface. Easy! And safe – guaranteed.",
  "social": "/images/Search/P_SearchThumb_Lupus_XT2.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Lupus_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "Lupus"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Lupus XT2 Plus' dateChanged='2020-04-21' author='Mike Polinowski' tag='INSTAR IP Camera' description='Integrate video surveillance systems such as IP cameras from LUPUS and lots of other manufacturers simply into the LUPUSEC user interface. Easy! And safe – guaranteed.' image='/images/Search/P_SearchThumb_Lupus_XT2.png' twitter='/images/Search/P_SearchThumb_Lupus_XT2.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Other_Platforms/Lupus_XT2_Plus/' locationFR='/fr/Software/Other_Platforms/Lupus_XT2_Plus/' crumbLabel="Lupus" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "other",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#other",
        "aria-label": "other permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Other`}</h2>
    <h3 {...{
      "id": "lupus-xt2-plus",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#lupus-xt2-plus",
        "aria-label": "lupus xt2 plus permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Lupus XT2 Plus`}</h3>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#adding-your-cameras-live-video"
        }}>{`Adding your Cameras Live Video`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#controlling-the-camera"
        }}>{`Controlling the Camera`}</a></li>
    </ul>
    {/* /TOC */}
    <h3 {...{
      "id": "adding-your-cameras-live-video",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#adding-your-cameras-live-video",
        "aria-label": "adding your cameras live video permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding your Cameras Live Video`}</h3>
    <p>{`Open in the LUPUS XT the menu `}<strong parentName="p">{`Smarthome`}</strong>{` / `}<strong parentName="p">{`Cameras`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cb48c313a2a0963c660f49fdbadae1ed/96e86/Lupus_XT2_Plus_for_INSTAR_Kameras_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACDElEQVQ4y4VSyY7aQBTs/4gG23gDDNju9to2eAFhkGEyE0WJgibKYZJIOeSQ/z9U9HrAmRwSDiVXV/vVW/qxqtkiz0vUdYMsyzGZepjN5vC8BWzbxVg3YZr2CwwLpmm94hd9/EdnpuVAeDa6ZIo09NAVJepcIo4TJFmCtM0QLH0EYomoSsD9EIHwFRd+gCDyERYc3A8gBAebezMY2h0s/Q6juzcI5nM4lgVN16EbOsb2GIbiGoy/uAF9NIKmj6BbhtKn0wmY9F24rgvbceE4DkLOIaIIQggUskCapIpHUYQgCBSP4xhxFGO1WiHPc3VHGuccrFmXiOJEib7vqwsppUJVVViv1yqIznQXhiG44OBCIEmSwYgSLZdLsL7v0TSNykY/FEWBzWaDuq4VJ9A9aWQqLkYEirkmojMlY1+fn/H56Qnn8xnHvh8M6ccsy1RQWZaDRl1QIIGMqLPrebFYgO0OB3SHA6q2RSpzFdy2LXIpVZXU9tWQWtfpUS7QNE3htcZ+fTrj54eP+PH2Ae+rCrIosN1uh9lRlWRM2tXQMIx/gn1/fIdvD4/4sj/gWJTI8lwZkCG1T9VRlTRHMr9p2PU9+vt7bPd7pFLC87xhTjQT27bVOhEmk4kK+p8pOx6POJ1O2HcdisvQo8se0svRV60K52otbhrudt0wcM5fFphaS9N0eBTipJH5LcPfitqbs1S8j9IAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cb48c313a2a0963c660f49fdbadae1ed/e4706/Lupus_XT2_Plus_for_INSTAR_Kameras_01.avif 230w", "/en/static/cb48c313a2a0963c660f49fdbadae1ed/d1af7/Lupus_XT2_Plus_for_INSTAR_Kameras_01.avif 460w", "/en/static/cb48c313a2a0963c660f49fdbadae1ed/7f308/Lupus_XT2_Plus_for_INSTAR_Kameras_01.avif 920w", "/en/static/cb48c313a2a0963c660f49fdbadae1ed/22b88/Lupus_XT2_Plus_for_INSTAR_Kameras_01.avif 1089w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cb48c313a2a0963c660f49fdbadae1ed/a0b58/Lupus_XT2_Plus_for_INSTAR_Kameras_01.webp 230w", "/en/static/cb48c313a2a0963c660f49fdbadae1ed/bc10c/Lupus_XT2_Plus_for_INSTAR_Kameras_01.webp 460w", "/en/static/cb48c313a2a0963c660f49fdbadae1ed/966d8/Lupus_XT2_Plus_for_INSTAR_Kameras_01.webp 920w", "/en/static/cb48c313a2a0963c660f49fdbadae1ed/37c82/Lupus_XT2_Plus_for_INSTAR_Kameras_01.webp 1089w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cb48c313a2a0963c660f49fdbadae1ed/81c8e/Lupus_XT2_Plus_for_INSTAR_Kameras_01.png 230w", "/en/static/cb48c313a2a0963c660f49fdbadae1ed/08a84/Lupus_XT2_Plus_for_INSTAR_Kameras_01.png 460w", "/en/static/cb48c313a2a0963c660f49fdbadae1ed/c0255/Lupus_XT2_Plus_for_INSTAR_Kameras_01.png 920w", "/en/static/cb48c313a2a0963c660f49fdbadae1ed/96e86/Lupus_XT2_Plus_for_INSTAR_Kameras_01.png 1089w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cb48c313a2a0963c660f49fdbadae1ed/c0255/Lupus_XT2_Plus_for_INSTAR_Kameras_01.png",
              "alt": "Lupus XT2 Plus & INSTAR Cameras",
              "title": "Lupus XT2 Plus & INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And load the `}<strong parentName="p">{`Camera Settings`}</strong>{` for the desired camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4adc9fb36a5579bd0c159e7d2bfb70be/f0b2f/Lupus_XT2_Plus_for_INSTAR_Kameras_02.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAc6HAyA//8QAFxABAQEBAAAAAAAAAAAAAAAAAQAQEf/aAAgBAQABBQJJu45//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAERQRBR/9oACAEBAAE/IUpkLCPBu6JOf//aAAwDAQACAAMAAAAQ8A//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAaEAADAQADAAAAAAAAAAAAAAAAAREhEEFR/9oACAEBAAE/EKxbV2Z3GnkLZKiJFt4//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4adc9fb36a5579bd0c159e7d2bfb70be/e4706/Lupus_XT2_Plus_for_INSTAR_Kameras_02.avif 230w", "/en/static/4adc9fb36a5579bd0c159e7d2bfb70be/d1af7/Lupus_XT2_Plus_for_INSTAR_Kameras_02.avif 460w", "/en/static/4adc9fb36a5579bd0c159e7d2bfb70be/7f308/Lupus_XT2_Plus_for_INSTAR_Kameras_02.avif 920w", "/en/static/4adc9fb36a5579bd0c159e7d2bfb70be/006c2/Lupus_XT2_Plus_for_INSTAR_Kameras_02.avif 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4adc9fb36a5579bd0c159e7d2bfb70be/a0b58/Lupus_XT2_Plus_for_INSTAR_Kameras_02.webp 230w", "/en/static/4adc9fb36a5579bd0c159e7d2bfb70be/bc10c/Lupus_XT2_Plus_for_INSTAR_Kameras_02.webp 460w", "/en/static/4adc9fb36a5579bd0c159e7d2bfb70be/966d8/Lupus_XT2_Plus_for_INSTAR_Kameras_02.webp 920w", "/en/static/4adc9fb36a5579bd0c159e7d2bfb70be/cff29/Lupus_XT2_Plus_for_INSTAR_Kameras_02.webp 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4adc9fb36a5579bd0c159e7d2bfb70be/e83b4/Lupus_XT2_Plus_for_INSTAR_Kameras_02.jpg 230w", "/en/static/4adc9fb36a5579bd0c159e7d2bfb70be/e41a8/Lupus_XT2_Plus_for_INSTAR_Kameras_02.jpg 460w", "/en/static/4adc9fb36a5579bd0c159e7d2bfb70be/4d836/Lupus_XT2_Plus_for_INSTAR_Kameras_02.jpg 920w", "/en/static/4adc9fb36a5579bd0c159e7d2bfb70be/f0b2f/Lupus_XT2_Plus_for_INSTAR_Kameras_02.jpg 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4adc9fb36a5579bd0c159e7d2bfb70be/4d836/Lupus_XT2_Plus_for_INSTAR_Kameras_02.jpg",
              "alt": "Lupus XT2 Plus & INSTAR Cameras",
              "title": "Lupus XT2 Plus & INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <Table01 mdxType="Table01" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/46fdb0aafa12aa4709af51a64e7eb203/302a4/Lupus_XT2_Plus_for_INSTAR_Kameras_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAACuElEQVQ4y22T20/baBDF/Xc0JCEhjh0nviW+5GabkAQCBLVUrQQUISAIVTxQVcBL232AVYvohVKWSnupdrXarrTbx6pP/ed+1fdBUbXbh6PxjDznnPGMlVYUU61W0TQdx7HxPZ84jvE8H8/zME0Ty7KxbUfGb1GpmBKXuYXvByg1YwLfmKBQmCCXy5PP57Ft8XIF07Sp1Tws08Q0K7JJCDiOI8WFYBiGV7kj+5S2Z5FELenI9z1ZbDabJElEvx8zHM4wvHWTTneaZrNFrVbD9y/d1+t1Go2GzAXEpEqv12N2dpYkSYiiNkEQsPPwgOPTM/ae7DJ/O2Fnd53j85ccvnhOu92iVr0kFe4EoSC/JlxeXmY02mJlZUWi2+sy2t7iw4f3vHt3xo+H+7w6+YGTk32eHN2nFdXleKJZiAuX4llA1JUoipifn2dubo5OpyOVDg4e8NcfP/Hn72/5/Okf/v34ieM3v/Dr2xfUQ58bqRTj4+NkMhmJbDZ7DUUQbWxssLq6ytLSEnGSsLK1xeHJM46fHfHbxUsuzl+z9+gx7//+majdJJUak8vL5XL/g9LtTnHnzl0WFxelS7GQ4c0F7q2vsTbaZHN7m/XRJmenR1ycPcWrVUmn07JZuPwav0IRyxAjLywsMBgM5MiGYcizELenFjVUtYhRKqFpGsVi8Zrouw7FNxwOh9Jdv98nCHyqrovrOOi6LolKui7JyoYh438J81dROmy1WkxNTRFHEWEQYDsOlutSNk0KxSJFXUfVNImyWZG1sUyGdDYr41gmTSqdJnMloog7SpJJhFMxrmNZdPUS0yWDmXKFnl5iUK5IiNpkQSXOTxDn8nQKKoOKyS3HpVFQSQuHYgmCTNxUIH4j22bScekHIdNhSNfzmQnrzNTr9HyfZrlMoGkSDcOgbVl0HBdXVaXLL7ICu6h7rg49AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/46fdb0aafa12aa4709af51a64e7eb203/e4706/Lupus_XT2_Plus_for_INSTAR_Kameras_03.avif 230w", "/en/static/46fdb0aafa12aa4709af51a64e7eb203/d1af7/Lupus_XT2_Plus_for_INSTAR_Kameras_03.avif 460w", "/en/static/46fdb0aafa12aa4709af51a64e7eb203/7f308/Lupus_XT2_Plus_for_INSTAR_Kameras_03.avif 920w", "/en/static/46fdb0aafa12aa4709af51a64e7eb203/d8ca6/Lupus_XT2_Plus_for_INSTAR_Kameras_03.avif 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/46fdb0aafa12aa4709af51a64e7eb203/a0b58/Lupus_XT2_Plus_for_INSTAR_Kameras_03.webp 230w", "/en/static/46fdb0aafa12aa4709af51a64e7eb203/bc10c/Lupus_XT2_Plus_for_INSTAR_Kameras_03.webp 460w", "/en/static/46fdb0aafa12aa4709af51a64e7eb203/966d8/Lupus_XT2_Plus_for_INSTAR_Kameras_03.webp 920w", "/en/static/46fdb0aafa12aa4709af51a64e7eb203/260c2/Lupus_XT2_Plus_for_INSTAR_Kameras_03.webp 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/46fdb0aafa12aa4709af51a64e7eb203/81c8e/Lupus_XT2_Plus_for_INSTAR_Kameras_03.png 230w", "/en/static/46fdb0aafa12aa4709af51a64e7eb203/08a84/Lupus_XT2_Plus_for_INSTAR_Kameras_03.png 460w", "/en/static/46fdb0aafa12aa4709af51a64e7eb203/c0255/Lupus_XT2_Plus_for_INSTAR_Kameras_03.png 920w", "/en/static/46fdb0aafa12aa4709af51a64e7eb203/302a4/Lupus_XT2_Plus_for_INSTAR_Kameras_03.png 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/46fdb0aafa12aa4709af51a64e7eb203/c0255/Lupus_XT2_Plus_for_INSTAR_Kameras_03.png",
              "alt": "Lupus XT2 Plus & INSTAR Cameras",
              "title": "Lupus XT2 Plus & INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`MJPEG paths for HD and Full HD camera models:`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <Table02 mdxType="Table02" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "controlling-the-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#controlling-the-camera",
        "aria-label": "controlling the camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Controlling the Camera`}</h3>
    <p>{`Lupus XT alarm systems support an `}<strong parentName="p">{`ActionURL`}</strong>{` function. This allows us to send `}<a parentName="p" {...{
        "href": "/en/Advanced_User/CGI_Commands/"
      }}>{`HTTP (CGI) commands`}</a>{` with up to 1000 characters per command to an INSTAR camera. This means that every CGI command can be easily used in an automation in the alarm system and executed either completely automatically (e.g. arming/disarming) depending on a condition or manually (e.g. via app / remote control / etc.).`}</p>
    <p>{`To do this, go to the `}<strong parentName="p">{`Automation Menu`}</strong>{` and create a new `}<strong parentName="p">{`Rule`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3a27c04cd532c4c5db60741b5eca73b7/29114/Lupus_XT2_Plus_for_INSTAR_Kameras_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.260869565217394%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA6ElEQVQY012Qa24DIQyEuUt2eRs25rFJVm2lXqHK/Y8yFY5oVv3xyTMwsg2qH5+ofUeIAcEHWOtgtIFxFnagNaxzMMbgclmgtYbWBsuyYlkWYV1X6FWDQoByFOC8lYYxRsF7Dz8GRA/vHFLKYGZpOgZaa0/1RSLC8XhAfTFj33f03tFaE/r+1oP7/Y7jOF53vf/lz0j2doP6cQ7XbcO2bcg5CyklEBFyTqLfPkudZwIRIhE4Z3y3BkWloLaGWqswt5p+UEoRpv6f41rxwYyn91BjuxEafzSYz5p+cG46/NQzV5hxZUaqFb+LKrsjkRPQNAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3a27c04cd532c4c5db60741b5eca73b7/e4706/Lupus_XT2_Plus_for_INSTAR_Kameras_04.avif 230w", "/en/static/3a27c04cd532c4c5db60741b5eca73b7/d1af7/Lupus_XT2_Plus_for_INSTAR_Kameras_04.avif 460w", "/en/static/3a27c04cd532c4c5db60741b5eca73b7/7f308/Lupus_XT2_Plus_for_INSTAR_Kameras_04.avif 920w", "/en/static/3a27c04cd532c4c5db60741b5eca73b7/e1c99/Lupus_XT2_Plus_for_INSTAR_Kameras_04.avif 1380w", "/en/static/3a27c04cd532c4c5db60741b5eca73b7/76ea5/Lupus_XT2_Plus_for_INSTAR_Kameras_04.avif 1840w", "/en/static/3a27c04cd532c4c5db60741b5eca73b7/f696c/Lupus_XT2_Plus_for_INSTAR_Kameras_04.avif 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3a27c04cd532c4c5db60741b5eca73b7/a0b58/Lupus_XT2_Plus_for_INSTAR_Kameras_04.webp 230w", "/en/static/3a27c04cd532c4c5db60741b5eca73b7/bc10c/Lupus_XT2_Plus_for_INSTAR_Kameras_04.webp 460w", "/en/static/3a27c04cd532c4c5db60741b5eca73b7/966d8/Lupus_XT2_Plus_for_INSTAR_Kameras_04.webp 920w", "/en/static/3a27c04cd532c4c5db60741b5eca73b7/445df/Lupus_XT2_Plus_for_INSTAR_Kameras_04.webp 1380w", "/en/static/3a27c04cd532c4c5db60741b5eca73b7/78de1/Lupus_XT2_Plus_for_INSTAR_Kameras_04.webp 1840w", "/en/static/3a27c04cd532c4c5db60741b5eca73b7/882b9/Lupus_XT2_Plus_for_INSTAR_Kameras_04.webp 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3a27c04cd532c4c5db60741b5eca73b7/81c8e/Lupus_XT2_Plus_for_INSTAR_Kameras_04.png 230w", "/en/static/3a27c04cd532c4c5db60741b5eca73b7/08a84/Lupus_XT2_Plus_for_INSTAR_Kameras_04.png 460w", "/en/static/3a27c04cd532c4c5db60741b5eca73b7/c0255/Lupus_XT2_Plus_for_INSTAR_Kameras_04.png 920w", "/en/static/3a27c04cd532c4c5db60741b5eca73b7/b1001/Lupus_XT2_Plus_for_INSTAR_Kameras_04.png 1380w", "/en/static/3a27c04cd532c4c5db60741b5eca73b7/161ec/Lupus_XT2_Plus_for_INSTAR_Kameras_04.png 1840w", "/en/static/3a27c04cd532c4c5db60741b5eca73b7/29114/Lupus_XT2_Plus_for_INSTAR_Kameras_04.png 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3a27c04cd532c4c5db60741b5eca73b7/c0255/Lupus_XT2_Plus_for_INSTAR_Kameras_04.png",
              "alt": "Lupus XT2 Plus & INSTAR Cameras",
              "title": "Lupus XT2 Plus & INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select `}<strong parentName="p">{`Mode`}</strong>{` as Condition:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/100a6ae7cd7543f8f74bbccc1fe91199/93582/Lupus_XT2_Plus_for_INSTAR_Kameras_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "29.565217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA+UlEQVQY012Q3Y6EIAyFfYi5nMxmxh/cBBEEUcHf2Xjh+z/Q2bSJG2cvvpT2nEJLYozBiVIKWmuO1lrUdf3Bf88Za6UwDAP6vkcipURZlhBCoCgKdF2HdV3ZEGPEPM+chxD+2LaN6yFEjHHENE9wtuGehMQr1BzHEZVSyPMcbdviOA4sy8IaefZ95+Yh9Oj7DtM04ef9Zi25vkzQBdYYeOcg8hzfQnzoNLVzDpWqYL2GbhTqWrPGK5NIifcejbWIxmCVEvb5hLjdIO93tN7zn5KXIjXShJTTAASdiSTLMpykacp/2RiDPMvw9Xggfb14ddKu3tN/ctZ+AZC6vrbx2CduAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/100a6ae7cd7543f8f74bbccc1fe91199/e4706/Lupus_XT2_Plus_for_INSTAR_Kameras_05.avif 230w", "/en/static/100a6ae7cd7543f8f74bbccc1fe91199/d1af7/Lupus_XT2_Plus_for_INSTAR_Kameras_05.avif 460w", "/en/static/100a6ae7cd7543f8f74bbccc1fe91199/7f308/Lupus_XT2_Plus_for_INSTAR_Kameras_05.avif 920w", "/en/static/100a6ae7cd7543f8f74bbccc1fe91199/e1c99/Lupus_XT2_Plus_for_INSTAR_Kameras_05.avif 1380w", "/en/static/100a6ae7cd7543f8f74bbccc1fe91199/5c99f/Lupus_XT2_Plus_for_INSTAR_Kameras_05.avif 1524w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/100a6ae7cd7543f8f74bbccc1fe91199/a0b58/Lupus_XT2_Plus_for_INSTAR_Kameras_05.webp 230w", "/en/static/100a6ae7cd7543f8f74bbccc1fe91199/bc10c/Lupus_XT2_Plus_for_INSTAR_Kameras_05.webp 460w", "/en/static/100a6ae7cd7543f8f74bbccc1fe91199/966d8/Lupus_XT2_Plus_for_INSTAR_Kameras_05.webp 920w", "/en/static/100a6ae7cd7543f8f74bbccc1fe91199/445df/Lupus_XT2_Plus_for_INSTAR_Kameras_05.webp 1380w", "/en/static/100a6ae7cd7543f8f74bbccc1fe91199/bc816/Lupus_XT2_Plus_for_INSTAR_Kameras_05.webp 1524w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/100a6ae7cd7543f8f74bbccc1fe91199/81c8e/Lupus_XT2_Plus_for_INSTAR_Kameras_05.png 230w", "/en/static/100a6ae7cd7543f8f74bbccc1fe91199/08a84/Lupus_XT2_Plus_for_INSTAR_Kameras_05.png 460w", "/en/static/100a6ae7cd7543f8f74bbccc1fe91199/c0255/Lupus_XT2_Plus_for_INSTAR_Kameras_05.png 920w", "/en/static/100a6ae7cd7543f8f74bbccc1fe91199/b1001/Lupus_XT2_Plus_for_INSTAR_Kameras_05.png 1380w", "/en/static/100a6ae7cd7543f8f74bbccc1fe91199/93582/Lupus_XT2_Plus_for_INSTAR_Kameras_05.png 1524w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/100a6ae7cd7543f8f74bbccc1fe91199/c0255/Lupus_XT2_Plus_for_INSTAR_Kameras_05.png",
              "alt": "Lupus XT2 Plus & INSTAR Cameras",
              "title": "Lupus XT2 Plus & INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select `}<strong parentName="p">{`Action-URL`}</strong>{` as the automation action:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f458ec82dc5885db4198caa9adf87914/c1b63/Lupus_XT2_Plus_for_INSTAR_Kameras_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.913043478260875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABaElEQVQoz12S626CQBCFeQNj/KXFohbdYlFgr1wMqGjiJfH93+Y0My0G/fFlh7NnDzsDnlIKWZbBWstQTVpf72papZRcG2OetdYau90OeZ7D68wdaZo+D1PdhwJIT5LkxSuVhLMWzjl41+sVt9sNh8MBx+ORuVwueDweOJ/PLzr57vc7a8R+v0fbntDsG2gl0dQ1vKIoEIYhZrMZ5vM5giDgW1BAVVVPqKWmaXhvHUX4nE4x9X34vo+yLHE6nfgFXtu23LuUCkpqaKVR1zVI70MHSNfOwRUFRBRhtlggWCyQFwV7ONBog1QmcDvNSJXywOmGXWsMPdc1DlpDhiHEeIzvyQTRxwfqfmD3paT6oxs4hfbRxsBmGcr1GlYIBMMhpoMBvkYjFOS3FkZreN3vYo2Fs45nROt7IEG+sqrg8hxJmmKz3fLKYf8eb7VaYblcQgiBTbxBHMeIf2KIlWCd9t/pdDrDWs/3CyYBIKnfJaRHAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f458ec82dc5885db4198caa9adf87914/e4706/Lupus_XT2_Plus_for_INSTAR_Kameras_06.avif 230w", "/en/static/f458ec82dc5885db4198caa9adf87914/d1af7/Lupus_XT2_Plus_for_INSTAR_Kameras_06.avif 460w", "/en/static/f458ec82dc5885db4198caa9adf87914/7f308/Lupus_XT2_Plus_for_INSTAR_Kameras_06.avif 920w", "/en/static/f458ec82dc5885db4198caa9adf87914/07e28/Lupus_XT2_Plus_for_INSTAR_Kameras_06.avif 1200w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f458ec82dc5885db4198caa9adf87914/a0b58/Lupus_XT2_Plus_for_INSTAR_Kameras_06.webp 230w", "/en/static/f458ec82dc5885db4198caa9adf87914/bc10c/Lupus_XT2_Plus_for_INSTAR_Kameras_06.webp 460w", "/en/static/f458ec82dc5885db4198caa9adf87914/966d8/Lupus_XT2_Plus_for_INSTAR_Kameras_06.webp 920w", "/en/static/f458ec82dc5885db4198caa9adf87914/92f8c/Lupus_XT2_Plus_for_INSTAR_Kameras_06.webp 1200w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f458ec82dc5885db4198caa9adf87914/81c8e/Lupus_XT2_Plus_for_INSTAR_Kameras_06.png 230w", "/en/static/f458ec82dc5885db4198caa9adf87914/08a84/Lupus_XT2_Plus_for_INSTAR_Kameras_06.png 460w", "/en/static/f458ec82dc5885db4198caa9adf87914/c0255/Lupus_XT2_Plus_for_INSTAR_Kameras_06.png 920w", "/en/static/f458ec82dc5885db4198caa9adf87914/c1b63/Lupus_XT2_Plus_for_INSTAR_Kameras_06.png 1200w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f458ec82dc5885db4198caa9adf87914/c0255/Lupus_XT2_Plus_for_INSTAR_Kameras_06.png",
              "alt": "Lupus XT2 Plus & INSTAR Cameras",
              "title": "Lupus XT2 Plus & INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now select a `}<a parentName="p" {...{
        "href": "/en/Advanced_User/CGI_Commands/"
      }}>{`CGI command`}</a>{` that you want to send to your camera. In our case, we use the command to pan our Full HD Pan&Tilt camera to `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Features/PTZ/"
      }}>{`preset position 1`}</a>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`preset`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-act`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`goto`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-number`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span></code></pre></div>
    <p>{`To use this command, you must prefix it with the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/IP_Configuration/"
      }}>{`IP address`}</a>{` of your camera and the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/IP_Configuration/"
      }}>{`Camera HTTP Port`}</a>{` and append your `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/System/User/"
      }}>{`admin camera login`}</a>{` at the end. As an example for a camera with the following data:`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <Table03 mdxType="Table03" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Does it look like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.178.21:8081/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`preset`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-act`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`goto`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-number`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-pwd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/89b98ed659fdb91a56481056902f6be4/84f4d/Lupus_XT2_Plus_for_INSTAR_Kameras_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABCklEQVQY002PW26DMBBFWQqQtAYSh5eNiXkZwUdFWEN/uo3u/VbXFSgfVzMMZ47GQV3XKIoCVVWhaRrfl2Xpv/M8h1IKZNhzxn9kOGdltNbo+x7GGAQEKOLSscxKiD1DEaOV8iz7d7ZWNRqt/4XzPGPbNgzDcGZdV+z7jmVZMI4jpmmCmyYsrxe+tg3c4UWMcw7D0KPV2nMBB7fbDVEUIY5jhGHon0EZgUNmncOPtVithXk+YRqDtm1hrfUsjziFXdedz2Al5EXOeVk3z/g2Br9C4C4livIB/SxRqQJSyrdLBwRpmkII4a88KmdMkiSQ9ztElmGOIsgsQ3i5IkkEPj6viC+x3yF3eP4A2P/CvRvaOxwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/89b98ed659fdb91a56481056902f6be4/e4706/Lupus_XT2_Plus_for_INSTAR_Kameras_07.avif 230w", "/en/static/89b98ed659fdb91a56481056902f6be4/d1af7/Lupus_XT2_Plus_for_INSTAR_Kameras_07.avif 460w", "/en/static/89b98ed659fdb91a56481056902f6be4/7f308/Lupus_XT2_Plus_for_INSTAR_Kameras_07.avif 920w", "/en/static/89b98ed659fdb91a56481056902f6be4/10565/Lupus_XT2_Plus_for_INSTAR_Kameras_07.avif 1208w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/89b98ed659fdb91a56481056902f6be4/a0b58/Lupus_XT2_Plus_for_INSTAR_Kameras_07.webp 230w", "/en/static/89b98ed659fdb91a56481056902f6be4/bc10c/Lupus_XT2_Plus_for_INSTAR_Kameras_07.webp 460w", "/en/static/89b98ed659fdb91a56481056902f6be4/966d8/Lupus_XT2_Plus_for_INSTAR_Kameras_07.webp 920w", "/en/static/89b98ed659fdb91a56481056902f6be4/ba162/Lupus_XT2_Plus_for_INSTAR_Kameras_07.webp 1208w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/89b98ed659fdb91a56481056902f6be4/81c8e/Lupus_XT2_Plus_for_INSTAR_Kameras_07.png 230w", "/en/static/89b98ed659fdb91a56481056902f6be4/08a84/Lupus_XT2_Plus_for_INSTAR_Kameras_07.png 460w", "/en/static/89b98ed659fdb91a56481056902f6be4/c0255/Lupus_XT2_Plus_for_INSTAR_Kameras_07.png 920w", "/en/static/89b98ed659fdb91a56481056902f6be4/84f4d/Lupus_XT2_Plus_for_INSTAR_Kameras_07.png 1208w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/89b98ed659fdb91a56481056902f6be4/c0255/Lupus_XT2_Plus_for_INSTAR_Kameras_07.png",
              "alt": "Lupus XT2 Plus & INSTAR Cameras",
              "title": "Lupus XT2 Plus & INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`As a second rule, we now add the command to move to the second `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Features/PTZ/"
      }}>{`saved position`}</a></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.178.21:8081/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`preset`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-act`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`goto`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-number`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-pwd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar`}</code></pre></div>
    <p>{`Then merge both rules into one profile:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3de7a4704724e4a561c7319616b12897/29114/Lupus_XT2_Plus_for_INSTAR_Kameras_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABgElEQVQoz02R22oUQRCG5yGEXGQ3O7MHMomZme6ePs1hD1HwRkIgIFEkKnrhE/gMgs/hjeCtz/dJ9bBLLj7+6uqq6r+7s912wFpHDIEQAlZZbG2xlcVphw+eruvo+x7vPTHGVCexcIyPmh0Oe5w1NKqhaRq6u57xccvwOOLfBJpqyldVdVKhrmu01iilEm3bYq0l67vI6/2AdRbnHfc/H/jw+4mPfz7z9scd0cbkUG4hLpxzqVF0HEd2ux3b7RalFXVTk03FU4EQxkDcxUToQ9ozrUIbleLnpJ4wIYbEaSZWxboxZkIbvPNopU95pRt0q9MbnQ6OgeAC5qWhFep2esP1ek1RFInlckmxnPSI5PO8wGjNMPQMw0DsIt54bt+/4tPfr3z7952HX++wxpJtNhtWqxVlWSaVtSAHCVdlyXqzoSwv00fIx9xUFeVlSdM17L8c6J9Gwn3H9dU1mQw5ulssFmnIcbCsRZ87zvOc+XyeuDifszi7YPbinNnZLM35D7Qe/puBACePAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3de7a4704724e4a561c7319616b12897/e4706/Lupus_XT2_Plus_for_INSTAR_Kameras_08.avif 230w", "/en/static/3de7a4704724e4a561c7319616b12897/d1af7/Lupus_XT2_Plus_for_INSTAR_Kameras_08.avif 460w", "/en/static/3de7a4704724e4a561c7319616b12897/7f308/Lupus_XT2_Plus_for_INSTAR_Kameras_08.avif 920w", "/en/static/3de7a4704724e4a561c7319616b12897/e1c99/Lupus_XT2_Plus_for_INSTAR_Kameras_08.avif 1380w", "/en/static/3de7a4704724e4a561c7319616b12897/76ea5/Lupus_XT2_Plus_for_INSTAR_Kameras_08.avif 1840w", "/en/static/3de7a4704724e4a561c7319616b12897/f696c/Lupus_XT2_Plus_for_INSTAR_Kameras_08.avif 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3de7a4704724e4a561c7319616b12897/a0b58/Lupus_XT2_Plus_for_INSTAR_Kameras_08.webp 230w", "/en/static/3de7a4704724e4a561c7319616b12897/bc10c/Lupus_XT2_Plus_for_INSTAR_Kameras_08.webp 460w", "/en/static/3de7a4704724e4a561c7319616b12897/966d8/Lupus_XT2_Plus_for_INSTAR_Kameras_08.webp 920w", "/en/static/3de7a4704724e4a561c7319616b12897/445df/Lupus_XT2_Plus_for_INSTAR_Kameras_08.webp 1380w", "/en/static/3de7a4704724e4a561c7319616b12897/78de1/Lupus_XT2_Plus_for_INSTAR_Kameras_08.webp 1840w", "/en/static/3de7a4704724e4a561c7319616b12897/882b9/Lupus_XT2_Plus_for_INSTAR_Kameras_08.webp 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3de7a4704724e4a561c7319616b12897/81c8e/Lupus_XT2_Plus_for_INSTAR_Kameras_08.png 230w", "/en/static/3de7a4704724e4a561c7319616b12897/08a84/Lupus_XT2_Plus_for_INSTAR_Kameras_08.png 460w", "/en/static/3de7a4704724e4a561c7319616b12897/c0255/Lupus_XT2_Plus_for_INSTAR_Kameras_08.png 920w", "/en/static/3de7a4704724e4a561c7319616b12897/b1001/Lupus_XT2_Plus_for_INSTAR_Kameras_08.png 1380w", "/en/static/3de7a4704724e4a561c7319616b12897/161ec/Lupus_XT2_Plus_for_INSTAR_Kameras_08.png 1840w", "/en/static/3de7a4704724e4a561c7319616b12897/29114/Lupus_XT2_Plus_for_INSTAR_Kameras_08.png 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3de7a4704724e4a561c7319616b12897/c0255/Lupus_XT2_Plus_for_INSTAR_Kameras_08.png",
              "alt": "Lupus XT2 Plus & INSTAR Cameras",
              "title": "Lupus XT2 Plus & INSTAR Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><img parentName="p" {...{
        "src": "/en/d3b44cd8b935931d20a9772065a48fa2/Lupus_XT2_Plus_for_INSTAR_Kameras_09.gif",
        "alt": "Lupus XT2 Plus & INSTAR Cameras"
      }}></img></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      